@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fade-in-right {
  from {
    transform: translateX(-15px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fade-in-right-wide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(50%);
  }
}

@keyframes fade-in-left {
  from {
    transform: translateX(15px);
  }
  to {
    transform: translateX(0);
  }
}

