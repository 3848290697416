$colors: (
  'black': #000,
  'light-black': #231f20,
  'white': #fff,
  'grey': #828282,
  'lime-dark': #318752,
  'pink': #c29595,
);

$breakpoints: (
  "sm": 320px,
  "md": 720px,
  "lg": 1024px,
  "xl": 1444px,
);


@function color($color-name) {
  @return map-get($colors, $color-name);
}

@each $color-name, $value in $colors {
  .color-#{$color-name},
  .text-#{$color-name} {
    color: $value;
  }

  .background-color-#{$color-name},
  .bg-#{$color-name} {
    background-color: $value;
  }

  .border-color-#{$color-name} {
    border-color: $value;
  }

  .fill-#{$color-name} {
    fill: $value;
  }

  .stroke-#{$color-name} {
    stroke: $value;
  }
}
