.title-sans-serif {
  font-family: minipax-regular;
}

@font-face {
  font-family: "minipax-regular";
  src: url("./minipax-regular.otf");
  font-weight: normal;
  font-style: normal;
}
